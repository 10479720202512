import React from "react";
import "./Interests.css";

class Interests extends React.Component {
  render() {
    return (
      <div className="interest-container">
        {/* <div className="skill-name">{}</div> */}
        <img className="interest-image" src={`data:image/svg+xml;utf8,${encodeURIComponent(this.props.interest.icon)}`} alt={this.props.interest.name}/>
      </div>
    );
  }
}

Interests.propTypes = {};

Interests.defaultProps = {};

export default Interests;
