import React from "react";
import "./Tag.css";

const Tag = (props) => (
  <span className="tag-container">
    <span className="text">{props.tag}</span>
    <span className="icon-arrow"></span>
  </span>
);

Tag.propTypes = {};

Tag.defaultProps = {};

export default Tag;
