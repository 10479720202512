import React from "react";
import "./Education.css";

class Education extends React.Component {
  render() {
    return (
      <div className="Education">
        <div className="school-image-wrap">
          <img
            src={this.props.level.schoolIcon}
            alt={this.props.level.name}
            className="school-icon"
          ></img>
        </div>
        <div className="school-details">
          <div className="small-school">{this.props.level.title}</div>
          <div className="small-school-name">
            {this.props.level.school}{" "}
            {this.props.level.schoolLink && (
              <a
                href={this.props.level.schoolLink}
                target="_blank"
                rel="noreferrer"
              >
                &#8599;
              </a>
            )}
          </div>
          <div className="small-school-date">{this.props.level.timeRange}</div>
          {this.props.level.marks && (
            <div className="small-school-date marks">
              {this.props.level.marks} 
            </div>
          )}
        </div>
      </div>
    );
  }
}

Education.propTypes = {};

Education.defaultProps = {};

export default Education;
