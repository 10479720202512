import React from "react";
// import PropTypes from "prop-types";
import "./Projects.css";
import Tag from "../Tag/Tag";
class Projects extends React.Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    this.myRef1 = React.createRef();
  }

  componentDidMount() {
    var coll = this.myRef.current;

    coll.addEventListener("click", function () {
      this.classList.toggle("active");
      this.childNodes[0].classList.toggle("arrowActive");
      var content = this.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    });
  }
  render() {
    return (
      <div className="project-container" id={this.props.project.id}>
        <div className="project-name">{this.props.project.name}</div>
        <p className="intro">{this.props.project.introduction}</p>
        <div className="tag-wrap">
          {this.props.project.tags.map((tag, index) => {
            return <Tag tag={tag} key={index} />;
          })}
        </div>
        <div className="project-btn-wrap">
          <button type="button" ref={this.myRef} className="collapsible">
            <span className="collapseArrow"></span>
          </button>
          <div className="content">
            <ul>
              {this.props.project.tasks.map((task, index) => {
                return (
                  <li key={index} className="skill-subname">
                    {task}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
