import React from "react";
import "./NavigationCards.css";

class NavigationCards extends React.Component {
  render() {
    return (
      <div className="main-nav">
        <div
          className="nav-container"
          key={(this.props.gridKey + 1).toString()}
          style={{
            backgroundImage: `url("${this.props.details.background}")`,
          }}
        >
          <div className="nav-title">
            <div className="titles-cont">
              <div
                className="titles"
                style={{
                  right: `${this.props.details.right}`,
                }}
              >
                {this.props.details.name.substring(
                  0,
                  this.props.details.name.length - 4
                )}
                <span className="titles-blue">
                  {this.props.details.name.substring(
                    this.props.details.name.length - 4,
                    this.props.details.name.length
                  )}
                </span>
              </div>
            </div>
            <div className="small-nav">
              {this.props.details.smallLinks.map((data, i) => {
                return (
                  <div key={i} className="small-link-name">
                    <a href={"#" + data.link}>{data.name}</a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="explore">
            <a href={"#" + this.props.details.name}>Explore.</a>
          </div>
        </div>
      </div>
    );
  }
}

NavigationCards.propTypes = {};

NavigationCards.defaultProps = {};

export default NavigationCards;
