import React from "react";
import "./Timeline.css";

class Timeline extends React.Component {
  render() {
    return (
      <div
        style={{ textAlign: this.props.keyValue % 2 === 0 ? "left" : "right" }}
        className="timeline-data"
      >
        <div>
          {this.props.job.organisation}
          <a href={this.props.job.link} target="_blank" rel="noreferrer">
            &#8599;
          </a>
        </div>
        {this.props.job.titles.map((smallJob, i) => {
          return (
            <div className="job-title" key={i}>
              <div className="small-job">{smallJob.name}</div>
              <div className="small-job-date">{smallJob.dateRange}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

Timeline.propTypes = {};

Timeline.defaultProps = {};

export default Timeline;
