import React from "react";
import "./Personal.css";
import Interests from "../Interests/Interests";
import Timeline from "../Timeline/Timeline";
import Education from "../Education/Education";

class Personal extends React.Component {
  render() {
    let toReturn;
    let toRender = [];
    let fullWidth = false;
    if (this.props.personal.name === "Interests") {
      this.props.personal.hobbies.forEach((interest, index) => {
        toRender.push(<Interests interest={interest} key={index} />);
      });
      toReturn = (
        <div>
          {/* <div className="topic-header-name">{this.props.personal.name}</div> */}
          <div className="">
            <div className="skill-name">{this.props.personal.name}</div>
            {toRender}
          </div>
        </div>
      );
    } else if (this.props.personal.name === "Languages") {
      this.props.personal.hobbies.forEach((interest, index) => {
        toRender.push(<Interests interest={interest} key={index} />);
      });
      toReturn = (
        <div>
          <div className="skill-name">{this.props.personal.name}</div>
          {toRender}
        </div>
      );
    } else if (this.props.personal.name === "Timeline") {
      fullWidth = true;
      this.props.personal.jobs.forEach((job, index) => {
        toRender.push(<Timeline job={job} key={index} keyValue={index} />);
      });
      toReturn = (
        <div className="timeline-wrap">
          <div className="skill-name">{this.props.personal.name}</div>
          {toRender}
        </div>
      );
    } else if (this.props.personal.name === "Education") {
      this.props.personal.levels.forEach((level, index) => {
        toRender.push(<Education level={level} key={index} />);
      });
      toReturn = (
        <div>
          <div className="skill-name">{this.props.personal.name}</div>
          {toRender}
        </div>
      );
    }
    return (
      <div
        className="personal-container"
        style={{ width: fullWidth ? "100%" : "" }}
        id={this.props.personal.id}
      >
        {toReturn}
      </div>
    );
  }
}

Personal.propTypes = {};

Personal.defaultProps = {};

export default Personal;
