import React from "react";
import "./SocailMediaIcons.css";

const SocailMediaIcons = (props) => (
  <a className="social-links" href={props.social.link}>
    <img
      className="social-icons"
      src={`data:image/svg+xml;utf8,${encodeURIComponent(props.social.icon)}`}
      alt={props.social.link}
    />
  </a>
);

SocailMediaIcons.propTypes = {};

SocailMediaIcons.defaultProps = {};

export default SocailMediaIcons;
