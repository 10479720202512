import React from "react";
import "./SkillBar.css";

class SkillBar extends React.Component {
  constructor() {
    super();

    this.myRef = React.createRef();
  }

  componentDidMount() {
    var element = this.myRef.current;
    var elementHeight = element.clientHeight;

    document.addEventListener("scroll", animate);
    function inView() {
      var windowHeight = window.innerHeight;
      var scrollY = window.scrollY || window.pageYOffset;
      var scrollPosition = scrollY + windowHeight;
      var elementPosition =
        element.getBoundingClientRect().top + scrollY + elementHeight;
      if (scrollPosition > elementPosition) {
        return true;
      }
      return false;
    }

    function animate() {
      if (inView()) {
        element.classList.add("animate");
      }
    }
  }

  render() {
    return (
      <div ref={this.myRef} className="skill-container" id={this.props.skill.id}>
        <div className="skill-name">{this.props.skill.name}</div>
        {this.props.skill.lists.map((smallSkill, index) => {
          return (
            <div key={index}>
              <div className="skill-subname">{smallSkill.name}</div>
              <div className="bar">
                <span
                  className="init"
                  style={{ "--my-css-var": smallSkill.level }}
                ></span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

SkillBar.propTypes = {};

SkillBar.defaultProps = {};

export default SkillBar;
