import React from "react";
import Contact from "../Contact/Contact";
import Personal from "../Personal/Personal";
import Projects from "../Projects/Projects";
import SkillBar from "../SkillBar/SkillBar";
import "./TopicHeader.css";

class TopicHeader extends React.Component {
  render() {
    let toReturn;
    let toRender = [];
    if (this.props.header.name === "Skills") {
      this.props.header.data.forEach((skill, index) => {
        toRender.push(<SkillBar skill={skill} key={index} />);
      });
      toReturn = (
        <div className="topic-wrap">
          <div className="topic-header-name">{this.props.header.name}</div>
          <div className="flex-handle backgroundAmoeba1">{toRender}</div>
        </div>
      );
    } else if (this.props.header.name === "Projects") {
      this.props.header.data.forEach((project, index) => {
        toRender.push(<Projects project={project} key={index} />);
      });
      toReturn = (
        <div className="topic-wrap">
          <div className="topic-header-name">{this.props.header.name}</div>
          <div className="backgroundAmoeba2 handle-flex-1">{toRender}</div>
        </div>
      );
    } else if (this.props.header.name === "Personal") {
      this.props.header.data.forEach((personal, index) => {
        toRender.push(
          <Personal
            className="personal-container topic-wrap"
            personal={personal}
            key={index}
          />
        );
      });
      toReturn = (
        <div className="topic-wrap">
          <div className="topic-header-name">{this.props.header.name}</div>
          <div className="backgroundAmoeba3 handle-flex-1">{toRender}</div>
        </div>
      );
    } else if (this.props.header.name === "Contact") {
      toReturn = (
        <div className="no-bottom">
          <div className="topic-header-name">{this.props.header.name}</div>
          <div className="backgroundAmoeba4">
            <Contact />
          </div>
          <div className="about-website">
            <hr style={{ width: "40%" }} />
            Built using React, <b>Interests Section</b>{" "}
            <div>
              Icons are made by{" "}
              <a href="https://www.freepik.com" title="Freepik">
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
              <b>, Timeline Section</b> CSS inspired by this{" "}
              <a href="https://codepen.io/snookca/full/qYoLaq">Codepen</a>.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="TopicHeader" id={this.props.header.name}>
        <div className="paper-clip-icon"></div>
        <div className="paper main-paper">{toReturn}</div>
      </div>
    );
  }
}

// get the element to animate

TopicHeader.propTypes = {};

TopicHeader.defaultProps = {};

export default TopicHeader;
