import React from "react";
import "./Contact.css";
import cqr from "../../assets/images/contact-qr.png"; // Tell Webpack this JS file uses this image

class Contact extends React.Component {
  render() {
    return (
      <div className="Contact">
        <img src={cqr} className="qr-image" alt="contact-qr" />
        <div className="conatct-title-wrap">
          <div className="contact-title">Roopam Shukla</div>
          <div className="contact-subtitle">
            Hoshanagabad, Madhya Pradesh, India, 461001
          </div>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
